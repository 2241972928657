import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import imgkarl from './Images/imgkarl.jpeg';
import 'remixicon/fonts/remixicon.css';

const Sidebar: React.FC = () => {
  const [selected, setSelected] = useState<string>('dashboard');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = (item: string) => {
    setSelected(item);
    navigate(`/${item}`);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login', { replace: true }); // Remplace l'entrée courante dans l'historique
  };
  

  return (
    <div className={`sidebar ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="search-container">
        <input type="text" placeholder="Rechercher" className="search-input" />
      </div>
      <div className="menu">
        <p className="menu-title">Menu</p>
        <div
          className={`menu-item ${selected === 'dashboard' ? 'selected' : ''}`}
          onClick={() => handleClick('dashboard/:projectId/')}
        >
          <i className="ri-dashboard-line"></i>
          <span> Tableau de bord </span>
        </div>
        <div
          className={`menu-item ${selected === 'leads' ? 'selected' : ''}`}
          onClick={() => handleClick('leads')}
        >
          <i className="ri-list-check-2"></i>
          <span>Leads</span>
        </div>
        <div
          className={`menu-item ${selected === 'interactions' ? 'selected' : ''}`}
          onClick={() => handleClick('interactions')}
        >
          <i className="ri-chat-3-line"></i>
          <span>Interactions</span>
        </div>
        <div
          className={`menu-item ${selected === 'clients' ? 'selected' : ''}`}
          onClick={() => handleClick('clients')}
        >
          <i className="ri-user-3-line"></i>
          <span>Clients</span>
        </div>
        <div
          className={`menu-item ${selected === 'taches' ? 'selected' : ''}`}
          onClick={() => handleClick('tasks')}
        >
          <i className="ri-task-line"></i>
          <span>Tâches</span>
        </div>
      </div>
      <div className="profile-menu-container">
        <div className="profile-button" onClick={toggleMenu}>
          <img src={imgkarl} alt="Profile" className="profile-picture" />
          <span>Karl-Elisée KOFFI</span>
          <i className={`ri-arrow-${isMenuOpen ? 'down' : 'up'}-s-line`}></i>
        </div>
        {isMenuOpen && (
          <div className="profile-menu drop-up">
            <div className="profile-info">
              <img src={imgkarl} alt="Profile" className="profile-picture" />
              <div className="profile-details">
                <p className="profile-name">Karl-Elisée KOFFI</p>
                <p className="profile-email">karlelisee777@gmail.com</p>
              </div>
            </div>
            <div className="profile-menu-item">
              <i className="ri-user-settings-line"></i>
              <span>Paramètres du compte</span>
            </div>
            <div className="profile-menu-item">
              <i className="ri-settings-3-line"></i>
              <span>Paramètres de l'application</span>
            </div>
            <div className="profile-menu-item">
              <i className="ri-moon-line"></i>
              <span>Mode sombre</span>
              <label className="switch">
                <input type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="profile-menu-item" onClick={handleLogout}>
              <i className="ri-logout-box-line"></i>
              <span>Se déconnecter</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
