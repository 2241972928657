import React, { useState } from "react";
import { X } from "react-feather";
import "./Editable.css";

// Définition des types pour les props
interface EditableProps {
  defaultValue?: string; // Valeur par défaut pour le champ de texte
  placeholder?: string; // Placeholder pour le champ de texte
  text: string; // Texte affiché en mode non-édition
  buttonText?: string; // Texte du bouton de soumission
  displayClass?: string; // Classe CSS pour le mode d'affichage
  editClass?: string; // Classe CSS pour le mode d'édition
  onSubmit?: (value: string) => void; // Fonction appelée lors de la soumission
}

const Editable: React.FC<EditableProps> = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [inputText, setInputText] = useState(props.defaultValue || "");

  const submission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputText && props.onSubmit) {
      setInputText("");
      props.onSubmit(inputText);
    }
    setIsEditable(false);
  };

  return (
    <div className="editable">
      {isEditable ? (
        <form
          className={`editable_edit ${props.editClass || ""}`}
          onSubmit={submission}
        >
          <input
            type="text"
            value={inputText}
            placeholder={props.placeholder || props.text}
            onChange={(event) => setInputText(event.target.value)}
            autoFocus
          />
          <div className="editable_edit_footer">
            <button type="submit">{props.buttonText || "Ajouter"}</button>
            <X onClick={() => setIsEditable(false)} className="closeIcon" />
          </div>
        </form>
      ) : (
        <p
          className={`editable_display ${props.displayClass || ""}`}
          onClick={() => setIsEditable(true)}
        >
          {props.text}
        </p>
      )}
    </div>
  );
};

export default Editable;
