import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Leads.css';
import { useAuth } from './AuthContext';
import { AutoComplete } from 'antd'; // Importer AutoComplete d'Ant Design
import * as XLSX from 'xlsx';

interface Lead {
  id: string;
  nom: string;
  prenom: string;
  email: string;
  phoneNumber: string;
  adressePostal: string;
  ville: string;
  codePostal: string;
  projetId: string;
  status: string;
  typeArtisan: string;
  sourceLeads: string;
  progression: string;
  commentaires: string;
}

interface User {
  email: string;
  firstName: string;
  token: string;
  projectId: string;
  id: string;
}

const Leads: React.FC = () => {
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false); // Modal pour Excel
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentLeadId, setCurrentLeadId] = useState<string | null>(null);
  const [filterTypeArtisan, setFilterTypeArtisan] = useState<string>('');
  const [newLead, setNewLead] = useState<Omit<Lead, 'id'>>({
    nom: '',
    prenom: '',
    email: '',
    phoneNumber: '',
    adressePostal: '',
    codePostal: '',
    ville: '',
    projetId: '',
    status: 'leads',
    typeArtisan: '',
    sourceLeads: '',
    progression: 'non contacté',
    commentaires: '',
  });

  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  // Fonction pour importer des données depuis un fichier Excel
// Fonction pour importer le fichier Excel et envoyer les données à l'API
const handleExcelUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (!file) {
    setError('Aucun fichier sélectionné.');
    return;
  }

  // Préparation des données pour l'upload
  const formData = new FormData();
  formData.append('file', file);

  // Inclure le projectId dans le FormData
  if (projectId) {
    formData.append('projectId', projectId);
  } else {
    setError('Project ID manquant.');
    return;
  }

  try {
    // Envoi du fichier à la route '/importLeads'
    const response = await fetch('https://crm.alwaysdata.net/importLeads', {
      method: 'POST',
      body: formData, // Envoi des données sous forme de FormData
    });

    if (!response.ok) throw new Error(await response.text());

    const result = await response.text();
    console.log('Importation réussie :', result);

    // Rafraîchir les leads après l'importation
    await fetchLeads(projectId);

    setIsExcelModalOpen(false); // Fermer le modal après succès
  } catch (error) {
    console.error('Erreur lors de l\'importation du fichier Excel :', error);
    setError(`Erreur lors de l'importation : ${error instanceof Error ? error.message : ''}`);
  }
};


const fetchLeads = async (projectId: string) => {
  try {
    const response = await fetch(`https://crm.alwaysdata.net/getLeads?projectId=${encodeURIComponent(projectId)}`);
    if (!response.ok) throw new Error('Failed to fetch leads');
    const data = await response.json();
    setLeads(data);
    console.log('Leads récupérés avec succès :', data);
  } catch (error) {
    console.error('Error fetching leads:', error);
    setError('Erreur lors de la récupération des leads');
  }
};



  // Récupération des détails de l'utilisateur et vérification du token
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${encodeURIComponent(userEmail)}`);
        if (!response.ok) throw new Error('Failed to fetch user details');
        const data = await response.json();
        setUserDetails(data);
        setUserToken(data.token);
        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError("Erreur lors de la récupération des détails de l'utilisateur");
      }
    };

    const verifyToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });
        if (!response.ok) throw new Error('Failed to verify token');
        const data = await response.json();
        if (data.valid) {
          setTokenValid(true);
          decodeToken(token);
        } else {
          setTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setTokenValid(false);
        navigate('/login');
      }
    };

    const decodeToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/decodeToken', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });
        if (!response.ok) throw new Error('Failed to decode token');
        const data = await response.json();
        setProjectId(data.projectId);
        fetchLeads(data.projectId);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    };

    const fetchLeads = async (projectId: string) => {
      try {
        const response = await fetch(`https://crm.alwaysdata.net/getLeads?projectId=${encodeURIComponent(projectId)}`);
        if (!response.ok) throw new Error('Failed to fetch leads');
        const data = await response.json();
        setLeads(data);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setError('Erreur lors de la récupération des leads');
      }
    };

    if (userEmail) fetchUserDetails();
  }, [userEmail, navigate]);

  // Récupération des suggestions d'adresses
  const fetchAddressSuggestions = async (input: string) => {
    try {
      const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(input)}`);
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        setSuggestions(data.features.map((feature: any) => ({
          label: feature.properties.label,
          value: feature.properties.label,
          postcode: feature.properties.postcode,
          city: feature.properties.city,
        })));
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions d'adresse:", error);
    }
  };

  // Fonction appelée lorsqu'une suggestion est sélectionnée
  const handleSuggestionClick = (value: string, option: any) => {
    setNewLead((prevLead) => ({
      ...prevLead,
      adressePostal: option.value,
      ville: option.city || '',
      codePostal: option.postcode || '',
    }));
    setSuggestions([]); // Réinitialiser les suggestions après sélection
  };

  // Fonction pour gérer la saisie d'adresse avec un délai
  useEffect(() => {
    if (newLead.adressePostal.trim() !== '') {
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        fetchAddressSuggestions(newLead.adressePostal);
      }, 500);
      setTimer(newTimer);
    } else {
      setSuggestions([]);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [newLead.adressePostal]);

  // Fonction pour gérer les changements de champ
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewLead({ ...newLead, [name]: value });
    setError(null);
  };

// Fonction pour transformer les données avant l'envoi à l'API
const transformLeadData = (lead: Omit<Lead, 'id'>) => {
  // Crée un nouvel objet sans changer l'original et renomme "phoneNumber" en "phone"
  const { phoneNumber, ...rest } = lead;
  return { ...rest, phone: phoneNumber };
};

const handleAddLead = async () => {
  try {
    if (!newLead.nom || !newLead.prenom || !newLead.email || !newLead.phoneNumber || !newLead.adressePostal || !newLead.codePostal || !newLead.typeArtisan) {
      setError('Tous les champs sont obligatoires');
      return;
    }

    if (!projectId) throw new Error('Project ID is missing');

    // Transformer les données avant l'envoi
    const transformedLead = transformLeadData(newLead);

    const response = await fetch('https://crm.alwaysdata.net/createLead', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...transformedLead,
        projectId,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      if (errorText.includes('Lead déjà existant avec cet email')) {
        // Afficher un message d'erreur spécifique si le lead existe déjà
        setError('Un lead avec cet email existe déjà dans le CRM.');
      } else {
        // Lancer une erreur générique pour d'autres types d'erreurs
        throw new Error(errorText);
      }
      return;
    }

    const leadData = await response.json();
    setLeads([...leads, { ...newLead, id: leadData.id }]);
    handleModalClose();
  } catch (error) {
    console.error('Error creating lead:', error);
    setError(`Erreur lors de la création du lead: ${error instanceof Error ? error.message : ''}`);
  }
};


  // Fonction pour sélectionner un lead
  const handleSelectLead = (lead: Lead) => {
    setSelectedLead(lead);
  };

  // Fonction pour éditer un lead
  const handleEditLead = (lead: Lead) => {
    setIsEditing(true);
    setIsModalOpen(true);
    setCurrentLeadId(lead.id);
    setNewLead({ ...lead });
    setSelectedLead(lead);
  };

  // Fonction pour mettre à jour un lead
  const handleUpdateLead = async () => {
    try {
      if (!newLead.nom || !newLead.prenom || !newLead.email || !newLead.phoneNumber || !newLead.adressePostal || !newLead.codePostal || !newLead.typeArtisan) {
        setError('Tous les champs sont obligatoires');
        return;
      }

      if (!projectId || !currentLeadId) throw new Error('Project ID or Lead ID is missing');

      const response = await fetch('https://crm.alwaysdata.net/updateLead', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: currentLeadId, ...newLead }),
      });

      if (!response.ok) throw new Error(await response.text());

      const updatedLead = await response.json();
      setLeads(leads.map((lead) => (lead.id === currentLeadId ? { ...lead, ...updatedLead } : lead)));
      handleModalClose();
    } catch (error) {
      console.error('Error updating lead:', error);
      setError(`Erreur lors de la mise à jour du lead: ${error instanceof Error ? error.message : ''}`);
    }
  };

  // Fonction pour supprimer un lead
  const handleDeleteLead = async (leadId: string) => {
    try {
      const response = await fetch('https://crm.alwaysdata.net/deleteLead', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ leadId }),
      });

      if (!response.ok) throw new Error(await response.text());

      setLeads(leads.filter((lead) => lead.id !== leadId));
    } catch (error) {
      console.error('Error deleting lead:', error);
      setError(`Erreur lors de la suppression du lead: ${error instanceof Error ? error.message : ''}`);
    }
  };

  // Fonction pour fermer le modal
  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setCurrentLeadId(null);
    setNewLead({
      nom: '',
      prenom: '',
      email: '',
      phoneNumber: '',
      adressePostal: '',
      ville: '',
      codePostal: '',
      projetId: '',
      status: 'leads',
      typeArtisan: '',
      progression: '',
      sourceLeads: '',
      commentaires: '',
    });
  };

  return (
    <div className="leads">
      <h2>Leads</h2>
      <div className="leads-actions">
        <button className="add-lead-button" onClick={() => setIsModalOpen(true)}>Ajouter un Lead</button>

        <select
          className="filter-input"
          value={filterTypeArtisan}
          onChange={(e) => setFilterTypeArtisan(e.target.value)}
        >
          <option value="">Tous les types d'artisans</option>
          <option value="Plombier">Plombier</option>
          <option value="Maçon">Maçon</option>
          <option value="Électricien">Électricien</option>
          <option value="Menuisier">Menuisier</option>
        </select>

        <button className="import-excel-button" onClick={() => setIsExcelModalOpen(true)}>Importer depuis Excel</button>
      </div>

      <table className="leads-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th>Adresse postale</th>
            <th>Ville</th>
            <th>Code Postal</th>
            <th>Type d'artisan</th>
            <th>Statut</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leads
            .filter((lead) => filterTypeArtisan === '' || lead.typeArtisan === filterTypeArtisan)
            .map((lead) => (
              <tr key={lead.id} onClick={() => handleSelectLead(lead)}>
                <td>{lead.nom}</td>
                <td>{lead.prenom}</td>
                <td>{lead.phoneNumber}</td>
                <td>{lead.email}</td>
                <td>{lead.adressePostal}</td>
                <td>{lead.ville}</td>
                <td>{lead.codePostal}</td>
                <td>{lead.typeArtisan}</td>
                <td>{lead.status}</td>
                <td>
                  <button className="action-button edit-button" onClick={() => handleEditLead(lead)}>Modifier</button>
                  <button className="action-button delete-button close-button" onClick={() => handleDeleteLead(lead.id)}>Supprimer</button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {selectedLead && (
        <div className="lead-details">
          <h3>Détails du Lead Sélectionné</h3>
          <p><strong>Nom :</strong> {selectedLead.nom} {selectedLead.prenom}</p>
          <p><strong>Email :</strong> {selectedLead.email}</p>
          <p><strong>Téléphone :</strong> {selectedLead.phoneNumber}</p>
          <p><strong>Adresse :</strong> {selectedLead.adressePostal}, {selectedLead.ville}, {selectedLead.codePostal}</p>
          <p><strong>Type d'Artisan :</strong> {selectedLead.typeArtisan}</p>
          <p><strong>Source :</strong> {selectedLead.sourceLeads}</p>
          <p><strong>Progression :</strong> {selectedLead.progression}</p>
          <p><strong>Commentaires :</strong> {selectedLead.commentaires}</p>
          <button onClick={() => setSelectedLead(null)} className="close-button">Fermer</button>
        </div>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>{isEditing ? 'Modifier le Lead' : 'Ajouter un nouveau Lead'}</h3>
            <input
              type="text"
              name="nom"
              placeholder="Nom"
              value={newLead.nom}
              onChange={handleInputChange}
              className="modal-input"
            />
            <input
              type="text"
              name="prenom"
              placeholder="Prénom"
              value={newLead.prenom}
              onChange={handleInputChange}
              className="modal-input"
            />
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={newLead.email}
              onChange={handleInputChange}
              className="modal-input"
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Téléphone"
              value={newLead.phoneNumber}
              onChange={handleInputChange}
              className="modal-input"
            />

            {/* Champ d'adresse avec AutoComplete */}
            <AutoComplete
              options={suggestions}
              value={newLead.adressePostal}
              onSearch={fetchAddressSuggestions}
              onSelect={handleSuggestionClick}
              onChange={(value: any) => setNewLead((prevLead) => ({ ...prevLead, adressePostal: value }))}
              placeholder="Adresse postale"
              className="modal-inputs"
            />

            <input
              type="text"
              name="codePostal"
              placeholder="Code postal"
              value={newLead.codePostal}
              onChange={handleInputChange}
              className="modal-input"
            />

            <input
              type="text"
              name="ville"
              placeholder="Ville"
              value={newLead.ville}
              onChange={handleInputChange}
              className="modal-input"
            />

            <select name="typeArtisan" value={newLead.typeArtisan} onChange={handleInputChange} className="modal-input">
              <option value="">Sélectionner le type d'artisan</option>
              <option value="Plombier">Plombier</option>
              <option value="Électricien">Électricien</option>
              <option value="Menuisier">Menuisier</option>
              <option value="Maçon">Maçon</option>
              <option value="Couvreur">Couvreur</option>
              <option value="Peintre">Peintre</option>
              <option value="Carreleurs">Carreleurs</option>
              <option value="Chauffagistes">Chauffagistes</option>
              <option value="Garagistes">Garagistes</option>
              <option value="mécaniciens">mécaniciens</option>
              <option value="Exterminateurs">Exterminateurs</option>
            </select>

            <input
              type="text"
              name="sourceLeads"
              placeholder="Source du lead"
              value={newLead.sourceLeads}
              onChange={handleInputChange}
              className="modal-input"
            />

            <select name="progression" value={newLead.progression} onChange={handleInputChange} className="modal-input">
              <option value="non connecté">Non Contacté</option>
              <option value="contacté">Contacté</option>
              <option value="en suivi">En Suivi</option>
              <option value="essai gratuit">Essai Gratuit</option>
            </select>

            <textarea
              name="commentaires"
              placeholder="Commentaires"
              value={newLead.commentaires}
              onChange={handleInputChange}
              className="modal-input"
            />

            <div className="modal-buttons">
              {isEditing ? (
                <button onClick={handleUpdateLead} className="save-button">Mettre à jour</button>
              ) : (
                <button onClick={handleAddLead} className="save-button">Enregistrer</button>
              )}
              <button onClick={handleModalClose} className="cancel-button">Annuler</button>
            </div>
          </div>
        </div>
      )}

       {/* Modal pour importer un fichier Excel */}
       {isExcelModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Importer un fichier Excel</h3>
            <input
              type="file"
              accept=".xls,.xlsx"
              onChange={handleExcelUpload}
              className="modal-input"
            />
            <button onClick={() => setIsExcelModalOpen(false)} className="cancel-button">Annuler</button>
          </div>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Leads;
