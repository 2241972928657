import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import Dashboard from './Components/Dashboard';
import Clients from './Components/Clients';
import Leads from './Components/Leads';
import Connexion from './Components/Connexion';
import Inscription from './Components/Inscription';
import ProjectSelection from './Components/ProjectSelection';
import { AuthProvider, useAuth } from './Components/AuthContext';
import Taches from './Components/Tâches/Taches';
import './App.css';
import InteractionPage from './Components/InteractionPage';

const App: React.FC = () => {
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Connexion />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/project-selection" element={<ProjectSelection setSelectedProject={setSelectedProject} />} />
          <Route path="/*" element={<ProtectedRoutes selectedProject={selectedProject} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoutes: React.FC<{ selectedProject: string | null }> = ({ selectedProject }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!selectedProject) {
    return <Navigate to="/project-selection" />;
  }

  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content">
        <Routes>
          <Route path="/dashboard/:projectId/" element={<Dashboard />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/interactions" element={<InteractionPage />} />
           <Route path="/tasks" element={<Taches />} /> 

        </Routes>
      </div>
    </div>
  );
};

export default App;
