import React, { useEffect, useState } from "react";
import Board from "../Tâches/Board/Board";
import Editable from "./Editabled/Editable";
import { BoardType, CardType } from "../Tâches/types";
import "./Taches.css";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Clients from "../Clients";

const Taches: React.FC = () => {
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [boards, setBoards] = useState<BoardType[]>([]);
  const [targetCard, setTargetCard] = useState<{ bid: string; cid: string }>({
    bid: "",
    cid: "",
  });

  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [leads, setLeads] = useState<any[]>([]); // Pour stocker les leads
  const [selectedLeadName, setSelectedLeadName] = useState<string>(""); // Pour stocker le lead sélectionné
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log('Fetching user details for:', userEmail);
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${encodeURIComponent(userEmail)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUserInfo(data);
        console.log('User details fetched:', data);
        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Erreur lors de la récupération des détails de l\'utilisateur');
      }
    };

    const verifyToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to verify token');
        }

        const data = await response.json();
        if (data.valid) {
          console.log('Token is valid');
          setIsTokenValid(true);
          decodeToken(token);
        } else {
          console.log('Token is invalid');
          setIsTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsTokenValid(false);
        navigate('/login');
      }
    };

    const decodeToken = async (token: string) => {
      try {
        console.log('Decoding token:', token);
        const response = await fetch('https://crm.alwaysdata.net/decodeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to decode token');
        }

        const data = await response.json();
        console.log('Decoded token:', data);
        setProjectId(data.projectId);
        console.log('Project ID:', data.projectId);
        fetchTasks(data.projectId);
        fetchLeads(data.projectId); // Récupérer les leads lors du décodage du token
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    };

    const fetchTasks = async (projectId: string) => {
      try {
        console.log('Fetching tasks for projectId:', projectId);
        const response = await fetch('https://crm.alwaysdata.net/getTasks');
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Failed to fetch tasks. Status:', response.status, 'Error:', errorText);
          throw new Error('Failed to fetch tasks');
        }
        const tasks = await response.json();
        console.log('Fetched tasks:', tasks);

        // Convert tasks to boards format
        const boardsData = [
          {
            id: "board-1",
            title: "Tasks",
            cards: tasks.map((task: any) => ({
              id: task.id,
              title: task.description,
              labels: [],
              date: task.date_creation,
              tasks: [],
              leadsId: task.leadsId // Assurez-vous que leadsId est correctement utilisé
            })),
          },
        ];
        
        setBoards(boardsData);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setError('Erreur lors de la récupération des tâches');
      }
    };

    const fetchLeads = async (projectId: string) => {
      try {
        console.log('Fetching leads for projectId:', projectId);
        const response = await fetch(`https://crm.alwaysdata.net/getLeads?projectId=${encodeURIComponent(projectId)}`);
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Failed to fetch leads. Status:', response.status, 'Error:', errorText);
          throw new Error('Failed to fetch leads');
        }
        const data = await response.json();
        setLeads(data);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setError('Erreur lors de la récupération des leads');
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, navigate]);

  const addBoardHandler = (name: string) => {
    if (!isTokenValid) return;

    const tempBoards = [...boards];
    tempBoards.push({
      id: Date.now().toString() + Math.random().toString(),
      title: name,
      cards: [],
    });
    setBoards(tempBoards);
  };

  const removeBoard = (id: string) => {
    if (!isTokenValid) return;

    const index = boards.findIndex((item) => item.id === id);
    if (index < 0) return;

    const tempBoards = [...boards];
    tempBoards.splice(index, 1);
    setBoards(tempBoards);
  };

  const addCardHandler = async (id: string, title: string) => {
    if (!isTokenValid || !projectId) return;

    const lead = leads.find(lead => lead.name === selectedLeadName);
    if (!lead) {
      console.error('Lead not found');
      return;
    }

    const index = boards.findIndex((item) => item.id === id);
    if (index < 0) return;

    // Ajout de la carte localement
    const tempBoards = [...boards];
    const newCard: CardType = {
      id: Date.now().toString() + Math.random().toString(),
      title: `${lead.name} - ${title}`, // Inclure le nom du lead dans le titre
      labels: [],
      date: "",
      tasks: [],
      leadsId: [lead.id], // Utiliser l'id du lead sélectionné
    };
    tempBoards[index].cards.push(newCard);
    setBoards(tempBoards);

    // Envoi de la nouvelle tâche au backend
    try {
      const response = await fetch('https://crm.alwaysdata.net/createTask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: `${lead.name} - ${title}`, // Inclure le nom du lead dans la description
          leadsId: lead.id, // Utiliser l'id du lead sélectionné
          status: "new", // Statut par défaut
          email: userEmail, // Ajout de l'email utilisateur
          date_realisation: "", // Date de réalisation, à remplir si nécessaire
          titre: title // Ajout du titre
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create task. Error: ${errorText}`);
      }

      console.log('Task created successfully');
      // Re-fetch tasks after adding a new one to update the board
      // fetchTasks(projectId);
    } catch (error) {
      console.error('Error creating task:', error);
      setError('Erreur lors de la création de la tâche');
    }
  };

  const removeCard = (bid: string, cid: string) => {
    if (!isTokenValid) return;

    const index = boards.findIndex((item) => item.id === bid);
    if (index < 0) return;

    const tempBoards = [...boards];
    const cards = tempBoards[index].cards;

    const cardIndex = cards.findIndex((item) => item.id === cid);
    if (cardIndex < 0) return;

    cards.splice(cardIndex, 1);
    setBoards(tempBoards);
  };

  const dragEnded = (bid: string, cid: string) => {
    if (!isTokenValid) return;

    let s_boardIndex, s_cardIndex, t_boardIndex, t_cardIndex;
    s_boardIndex = boards.findIndex((item) => item.id === bid);
    if (s_boardIndex < 0) return;

    s_cardIndex = boards[s_boardIndex].cards.findIndex(
      (item) => item.id === cid
    );
    if (s_cardIndex < 0) return;

    t_boardIndex = boards.findIndex((item) => item.id === targetCard.bid);
    if (t_boardIndex < 0) return;

    t_cardIndex = boards[t_boardIndex].cards.findIndex(
      (item) => item.id === targetCard.cid
    );
    if (t_cardIndex < 0) return;

    const tempBoards = [...boards];
    const sourceCard = tempBoards[s_boardIndex].cards[s_cardIndex];
    tempBoards[s_boardIndex].cards.splice(s_cardIndex, 1);
    tempBoards[t_boardIndex].cards.splice(t_cardIndex, 0, sourceCard);
    setBoards(tempBoards);

    setTargetCard({
      bid: "",
      cid: "",
    });
  };

  const dragEntered = (bid: string, cid: string) => {
    if (!isTokenValid) return;

    if (targetCard.cid === cid) return;
    setTargetCard({
      bid,
      cid,
    });
  };

  const updateCard = (bid: string, cid: string, card: CardType) => {
    if (!isTokenValid) return;

    const index = boards.findIndex((item) => item.id === bid);
    if (index < 0) return;

    const tempBoards = [...boards];
    const cards = tempBoards[index].cards;

    const cardIndex = cards.findIndex((item) => item.id === cid);
    if (cardIndex < 0) return;

    tempBoards[index].cards[cardIndex] = card;

    setBoards(tempBoards);
  };

  useEffect(() => {
    if (isTokenValid) {
      localStorage.setItem("prac-kanban", JSON.stringify(boards));
    }
  }, [boards, isTokenValid]);

  const handleFilterChange = (filter: string) => {
    const filteredBoards = boards.map(board => ({
      ...board,
      cards: board.cards.filter(card => {
        if (filter === "all") return true;
        if (filter === "leads") return card.leadsId && card.leadsId.some(id => leads.some(lead => lead.id === id && lead.status === 'leads'));
        if (filter === "clients") return card.leadsId && card.leadsId.some(id => leads.some(lead => lead.id === id && lead.status === 'client'));
        return false;
      })
    }));
    setBoards(filteredBoards);
  };

  return (
    <div className="app">
      <div className="app_nav">
        <h1>Gestionnaire de tâches</h1>
      </div>
      <div className="app_boards_container">
        <div className="app_boards_last">
          <Editable
            displayClass="app_boards_add-board"
            editClass="app_boards_add-board_edit"
            placeholder="Entrez un nom pour la liste"
            text="Ajouter une liste"
            buttonText="Ajouter une liste"
            onSubmit={addBoardHandler}
          />
          <select onChange={(e) => handleFilterChange(e.target.value)}>
            <option value="all">Tous</option>
          {leads.map((lead) => (
            <option key={lead.id} value={lead.name}>{lead.name} - Leads</option>
          ))}
         
            <option value="clients">Clients</option>
          </select>
        </div>
        <div className="app_boards">
          {boards.map((item) => (
            <Board
              key={item.id}
              board={item}
              addCard={addCardHandler}
              removeBoard={() => removeBoard(item.id)}
              removeCard={removeCard}
              dragEnded={dragEnded}
              dragEntered={dragEntered}
              updateCard={updateCard}
            />
          ))}
        </div>
      </div>
      {/* <div className="app_boards_leads">
        <h2>Ajouter une tâche</h2>
        <select onChange={(e) => setSelectedLeadName(e.target.value)} value={selectedLeadName}>
          <option value="">Sélectionner un lead</option>
          {leads.map((lead) => (
            <option key={lead.id} value={lead.name}>{lead.name}</option>
          ))}
        </select>
        <Editable
          displayClass="app_boards_add-task"
          editClass="app_boards_add-task_edit"
          placeholder="Entrez un nom pour la tâche"
          text="Ajouter une tâche"
          buttonText="Ajouter une tâche"
          onSubmit={(title) => addCardHandler("board-1", title)}
        />
      </div> */}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Taches;
