import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './InteractionPage.css';
import { useAuth } from './AuthContext';
import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'; // Pour la localisation en français

interface Interaction {
  id: string;
  title: string;
  description: string;
  leadId: string;
  date: string;
  action_a_realiser: string;
  commercialId: string; // Peut être remplacé par 'id' selon la structure de la réponse
}

interface Lead {
  id: string;
  name: string;
}

const InteractionPage: React.FC = () => {
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [newInteraction, setNewInteraction] = useState<Partial<Interaction>>({});
  const [editingInteraction, setEditingInteraction] = useState<Interaction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log('Fetching user details for:', userEmail);
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${encodeURIComponent(userEmail)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUserDetails(data);
        setUserToken(data.token);
        console.log('User details fetched:', data);
        console.log('Commercial ID:', data.id);

        if (data.id) {
          fetchInteractionsByCommercialId(data.id);
        } else {
          console.error('Commercial ID not found in user details');
        }

        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Erreur lors de la récupération des détails de l\'utilisateur');
      }
    };

    const verifyToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to verify token');
        }

        const data = await response.json();
        if (data.valid) {
          console.log('Token is valid');
          setIsTokenValid(true);
          decodeToken(token);
        } else {
          console.log('Token is invalid');
          setIsTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsTokenValid(false);
        navigate('/login');
      }
    };

    const decodeToken = async (token: string) => {
      try {
        console.log('Decoding token:', token);
        const response = await fetch('https://crm.alwaysdata.net/decodeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to decode token');
        }

        const data = await response.json();
        console.log('Decoded token:', data);
        setProjectId(data.projectId);
        console.log('Project ID:', data.projectId);
        fetchLeads(data.projectId);

      } catch (error) {
        console.error('Error decoding token:', error);
      }
    };

    const fetchLeads = async (projectId: string) => {
      try {
        console.log('Fetching leads for projectId:', projectId);
        const response = await fetch(`https://crm.alwaysdata.net/getLeads?projectId=${encodeURIComponent(projectId)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch leads');
        }
        const data = await response.json();
        console.log('Fetched Leads:', data);
        setLeads(data);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setError('Erreur lors de la récupération des leads');
      }
    };

    const fetchInteractionsByCommercialId = async (commercialId: string) => {
      try {
        console.log('Fetching interactions for commercialId:', commercialId);
        const response = await fetch(`https://crm.alwaysdata.net/getInteractionsByCommercialId/${commercialId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched Interactions:', data);
        setInteractions(data);
      } catch (error) {
        console.error('Error fetching interactions:', error);
        setError('Erreur lors de la récupération des interactions');
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, navigate]);

  const createInteraction = async () => {
    if (!newInteraction.title || !newInteraction.description || !newInteraction.leadId || !newInteraction.date || !newInteraction.action_a_realiser || !userDetails?.id) {
      setError('Tous les champs sont requis');
      return;
    }

    try {
      const response = await fetch('https://crm.alwaysdata.net/createInteraction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...newInteraction, commercialId: userDetails.id }),
      });

      if (!response.ok) {
        throw new Error('Failed to create interaction');
      }

      const data = await response.json();
      setInteractions([...interactions, { ...newInteraction, id: data.id, creationDate: data.creationDate, commercialId: userDetails.id } as Interaction]);
      setNewInteraction({});
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating interaction:', error);
      setError('Erreur lors de la création de l\'interaction');
    }
  };

  const updateInteraction = async () => {
    if (!editingInteraction) return;

    try {
      const response = await fetch('https://crm.alwaysdata.net/updateInteraction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingInteraction),
      });

      if (!response.ok) {
        throw new Error('Failed to update interaction');
      }

      setInteractions(interactions.map(interaction => interaction.id === editingInteraction.id ? editingInteraction : interaction));
      setEditingInteraction(null);
    } catch (error) {
      console.error('Error updating interaction:', error);
      setError('Erreur lors de la modification de l\'interaction');
    }
  };

  const deleteInteraction = async (id: string) => {
    try {
      const response = await fetch('https://crm.alwaysdata.net/deleteInteraction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ interactionId: id }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete interaction');
      }

      setInteractions(interactions.filter(interaction => interaction.id !== id));
    } catch (error) {
      console.error('Error deleting interaction:', error);
      setError('Erreur lors de la suppression de l\'interaction');
    }
  };

  return (
    <div className="interaction-page">
      <h2>Interactions</h2>
      {error && <p className="error-message">{error}</p>}
      <button onClick={() => setIsModalOpen(true)}>+ Ajouter une interaction</button>
      <div className="interaction-list">
        {interactions.map(interaction => (
          <div key={interaction.id} className="interaction-item">
            <h3>{interaction.title}</h3>
            <p>{interaction.description}</p>
            <p><strong>Date:</strong> {isValid(parseISO(interaction.date)) ? format(new Date(interaction.date), 'dd/MM/yyyy', { locale: fr }) : 'Date invalide'}</p>
            <p><strong>Action réalisé:</strong> {interaction.action_a_realiser}</p>
            <button onClick={() => { setEditingInteraction(interaction); setIsModalOpen(true); }}>Modifier</button>
            <button onClick={() => deleteInteraction(interaction.id)}>Supprimer</button>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>{editingInteraction ? 'Modifier l\'interaction' : 'Créer une nouvelle interaction'}</h3>
            <input
              type="text"
              placeholder="Titre"
              value={editingInteraction ? editingInteraction.title : newInteraction.title || ''}
              onChange={(e) => {
                const value = e.target.value;
                if (editingInteraction) {
                  setEditingInteraction({ ...editingInteraction, title: value });
                } else {
                  setNewInteraction({ ...newInteraction, title: value });
                }
              }}
            />
            <textarea
              placeholder="Description"
              value={editingInteraction ? editingInteraction.description : newInteraction.description || ''}
              onChange={(e) => {
                const value = e.target.value;
                if (editingInteraction) {
                  setEditingInteraction({ ...editingInteraction, description: value });
                } else {
                  setNewInteraction({ ...newInteraction, description: value });
                }
              }}
            ></textarea>
            <select
              value={editingInteraction ? editingInteraction.leadId : newInteraction.leadId || ''}
              onChange={(e) => {
                const value = e.target.value;
                if (editingInteraction) {
                  setEditingInteraction({ ...editingInteraction, leadId: value });
                } else {
                  setNewInteraction({ ...newInteraction, leadId: value });
                }
              }}
            >
              <option value="">Sélectionner un Lead</option>
              {leads.map(lead => (
                <option key={lead.id} value={lead.id}>{lead.name}</option>
              ))}
            </select>
            <DatePicker
              selected={editingInteraction ? (isValid(parseISO(editingInteraction.date)) ? new Date(editingInteraction.date) : null) : (newInteraction.date ? new Date(newInteraction.date) : null)}
              onChange={(date) => {
                const dateStr = date ? format(date, 'yyyy-MM-dd') : '';
                if (editingInteraction) {
                  setEditingInteraction({ ...editingInteraction, date: dateStr });
                } else {
                  setNewInteraction({ ...newInteraction, date: dateStr });
                }
              }}
              dateFormat="dd/MM/yyyy"
              locale={fr}
            />
            <input
              type="text"
              placeholder="Action à réaliser"
              value={editingInteraction ? editingInteraction.action_a_realiser : newInteraction.action_a_realiser || ''}
              onChange={(e) => {
                const value = e.target.value;
                if (editingInteraction) {
                  setEditingInteraction({ ...editingInteraction, action_a_realiser: value });
                } else {
                  setNewInteraction({ ...newInteraction, action_a_realiser: value });
                }
              }}
            />
            <input
              type="text"
              placeholder="Commercial ID"
              value={userDetails?.id || ''}
              readOnly
            />
            <button onClick={editingInteraction ? updateInteraction : createInteraction}>
              {editingInteraction ? 'Enregistrer les modifications' : 'Créer'}
            </button>
            <button onClick={() => { setIsModalOpen(false); setEditingInteraction(null); }}>Annuler</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteractionPage;
