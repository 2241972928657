import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Dashboard.css';
import { useAuth } from './AuthContext';
import { format, isValid } from 'date-fns';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Important for screen readers

interface Task {
  assignTo: string;
  date_creation: string;
  date_modification: string;
  date_realisation: string;
  description: string;
  id: string;
  leadsId: string;
  status: string;
}

interface User {
  email: string;
  firstName: string;
  token: string;
}

const Dashboard: React.FC = () => {
  const { userEmail, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState<Task>({
    assignTo: '',
    date_creation: '',
    date_modification: '',
    date_realisation: '',
    description: '',
    id: '',
    leadsId: '',
    status: ''
  });
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [taskDetails, setTaskDetails] = useState<Task | null>(null);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);

  // Effect to handle authentication and fetch tasks
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not authenticated
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${userEmail}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUserDetails(data);
        setUserToken(data.token);
        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Erreur lors de la récupération des détails de l\'utilisateur');
      }
    };

    const verifyToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();
        if (data.valid) {
          setTokenValid(true);
          fetchTasks(); // Fetch tasks only after token validation
        } else {
          setTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setTokenValid(false);
        navigate('/login');
      }
    };

    const fetchTasks = async () => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/getTasks');
        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }
        const data = await response.json();
        setTasks(data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setError('Erreur lors de la récupération des tâches');
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, isAuthenticated, navigate]);

  // Function to add a new task
  const addTask = () => {
    if (newTask.description.trim() !== '') {
      setTasks([...tasks, { ...newTask, id: Date.now().toString() }]);
      setNewTask({
        assignTo: '',
        date_creation: '',
        date_modification: '',
        date_realisation: '',
        description: '',
        id: '',
        leadsId: '',
        status: ''
      });
      setIsModalOpen(false);
    }
  };

  // Function to delete a task
  const deleteTask = (id: string) => {
    setTasks(tasks.filter(task => task.id !== id));
  };

  // Function to edit a task
  const editTask = (task: Task) => {
    setEditingTask(task);
  };

  // Function to save the edited task
  const saveTask = () => {
    if (editingTask) {
      setTasks(tasks.map(task => (task.id === editingTask.id ? editingTask : task)));
      setEditingTask(null);
    }
  };

  // Handle date selection for the calendar
  const handleDateSelect = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      const task = tasks.find(task => isValid(new Date(task.date_realisation)) && format(new Date(task.date_realisation), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
      if (task) {
        setTaskDetails(task);
        setIsModalOpen(true);
      }
    }
  };

  return (
    <div className="dashboard">
      <h2>Tableau de bord</h2>
      <p className="textdashboard">Explorez notre tableau de bord pour une visualisation rapide de vos données clés.</p>
      <div className="dashboard-welcome">
        <h3>Bienvenue dans votre tableau de bord, {userDetails?.firstName} !</h3>
        <p>Explorez notre tableau de bord pour une visualisation rapide de vos données clés.</p>
      </div>
      <div className="dashboard-content">
        <div className="tasks-container">
          <h4>Activités de PipePilot</h4>
          <div className="tasks">
            {tasks.map(task => (
              <div key={task.id} className="task-item">
                <p>{task.description}</p>
                <button onClick={() => deleteTask(task.id)}>Supprimer</button>
                <button onClick={() => editTask(task)}>Modifier</button>
              </div>
            ))}
            <button className="add-task-button" onClick={() => setIsModalOpen(true)}>+ Ajouter une tâche</button>
          </div>
        </div>
        <div className="calendar-container">
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date | null) => handleDateSelect(date)}
            inline
            dateFormat="dd/MM/yyyy"
            highlightDates={tasks.filter(task => isValid(new Date(task.date_realisation))).map(task => new Date(task.date_realisation))}
            dayClassName={(date: number | Date) =>
              tasks.some(task => isValid(new Date(task.date_realisation)) && format(new Date(task.date_realisation), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'))
                ? 'highlighted-task'
                : ''
            }
          />
        </div>
      </div>
      {isModalOpen && taskDetails && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Task Details"
        >
          <h3>Détails de la tâche</h3>
          <p><strong>Description:</strong> {taskDetails.description}</p>
          <p><strong>Assigné à:</strong> {taskDetails.assignTo}</p>
          <p><strong>Date de réalisation:</strong> {isValid(new Date(taskDetails.date_realisation)) ? format(new Date(taskDetails.date_realisation), 'dd/MM/yyyy HH:mm') : 'Date invalide'}</p>
          <p><strong>Statut:</strong> {taskDetails.status}</p>
          <button onClick={() => setIsModalOpen(false)}>Fermer</button>
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
