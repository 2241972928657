import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Importer le contexte
import './Connexion.css';

const Connexion: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserEmail } = useAuth(); // Utiliser le contexte

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email.endsWith('@wecyde.com')) {
      setError('Veuillez utiliser une adresse e-mail se terminant par @wecyde.com.');
      return;
    }

    try {
      const response = await fetch('https://crm.alwaysdata.net/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const responseData = await response.json();
          console.log('Response data:', responseData);
        } else {
          const responseText = await response.text();
          console.log('Response text:', responseText);
        }

        setIsAuthenticated(true);
        setUserEmail(email); // Stocker l'email
        navigate('/project-selection');
      } else {
        const errorText = await response.text();
        setError(errorText || 'Erreur lors de la connexion.');
      }
    } catch (error) {
      setError('Une erreur s\'est produite. Veuillez réessayer.');
    }
  };

  return (
    <div className="connexion-container">
      <h2>Connexion</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Mot de passe:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">Se connecter</button>
      </form>
      <p>
        Pas de compte ? <Link to="/inscription">Inscrivez-vous ici</Link>
      </p>
    </div>
  );
}

export default Connexion;
