import React, { useEffect } from "react";
import "./Dropdown.css";

interface DropdownProps {
  className?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ className, onClose, children }) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(`.${className}`)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [className, onClose]);

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Dropdown;
