import React, { useState, useEffect } from 'react';
import './Clients.css';
import imgkarl from './Images/imgkarl.jpeg';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

interface Task {
  id: string;
  title: string;
  description: string;
  date: string;
  status: string;
  leadsId: string[]; // Ajout de la propriété leadsId
}

interface Client {
  id: string;
  firstName: string;
  lastName: string;
  name: string; // Nom complet
  email: string;
  phone: string;
  status: string;
  interventions: number;
  clients: number;
  avatar: string;
  projetId: string;
  tasks?: Task[]; // Tâches liées au client
}

interface User {
  email: string;
  firstName: string;
  token: string;
  id: string;
}

const Clients: React.FC = () => {
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null); // Ajout de la variable selectedClient

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log('Fetching user details for:', userEmail);
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${encodeURIComponent(userEmail)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUserDetails(data);
        setUserToken(data.token);
        console.log('User details fetched:', data);
        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Erreur lors de la récupération des détails de l\'utilisateur');
      }
    };

    const verifyToken = async (token: string) => {
      try {
        console.log('Verifying token:', token);
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to verify token');
        }

        const data = await response.json();
        if (data.valid) {
          console.log('Token is valid');
          setTokenValid(true);
          decodeToken(token); // Appel à la fonction de décodage du token
        } else {
          console.log('Token is invalid');
          setTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setTokenValid(false);
        navigate('/login');
      }
    };

    const decodeToken = async (token: string) => {
      try {
        console.log('Decoding token:', token);
        const response = await fetch('https://crm.alwaysdata.net/decodeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to decode token');
        }

        const data = await response.json();
        console.log('Decoded token:', data);
        setProjectId(data.projectId);
        console.log('Project ID:', data.projectId); // Afficher le projectId dans la console
        fetchClientLeads(data.projectId);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    };

    const fetchClientLeads = async (projectId: string) => {
      try {
        console.log(`Fetching clients for projectId: ${projectId}`);
        const response = await fetch(`https://crm.alwaysdata.net/getClientLeads?projectId=${encodeURIComponent(projectId)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch client leads');
        }
        const data = await response.json();
        console.log('Fetched clients:', data);
        setClients(data);
        fetchClientTasks(data);
      } catch (error) {
        console.error('Error fetching client leads:', error);
        setError('Erreur lors de la récupération des clients');
      }
    };

    const fetchClientTasks = async (clients: Client[]) => {
      try {
        const clientTasksPromises = clients.map(async (client) => {
          const response = await fetch(`https://crm.alwaysdata.net/getTasks`);
          if (!response.ok) {
            throw new Error('Failed to fetch tasks for client');
          }
          const tasks = await response.json();
          const clientTasks = tasks.filter((task: Task) => task.leadsId.includes(client.id));
          return { ...client, tasks: clientTasks };
        });

        const clientsWithTasks = await Promise.all(clientTasksPromises);
        setClients(clientsWithTasks);
      } catch (error) {
        console.error('Error fetching tasks for clients:', error);
        setError('Erreur lors de la récupération des tâches des clients');
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, navigate]);

  const handleDeleteClient = async (clientId: string) => {
    try {
      const response = await fetch('https://crm.alwaysdata.net/deleteClient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientId }),
      });
      console.log('Client ID :', clientId)
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      setClients(clients.filter(client => client.id !== clientId));
      console.log('Client deleted:', clientId);
    } catch (error) {
      console.error('Error deleting client:', error);
      if (error instanceof Error) {
        setError(`Erreur lors de la suppression du client: ${error.message}`);
      } else {
        setError('Erreur lors de la suppression du client');
      }
    }
  };

  const handleClientClick = (client: Client) => {
    setSelectedClient(client);
  };

  return (
    <div className="clients">
      <h2>Clients</h2>
      <p>Accédez à notre page clients pour une vue détaillée et rapide de votre portefeuille de clients.</p>
      <div className="clients-summary">
        <div className="summary-box">
          <h3>Nombre de clients</h3>
          <p>{clients.length} Clients</p>
        </div>
        <div className="summary-box">
          <h3>Nouveaux Clients</h3>
          <p>{clients.length} Nouveaux clients</p>
          <div className="new-clients-avatars">
            {clients.slice(0, 3).map((client) => (
              <img key={client.id} src={client.avatar} alt="New client" />
            ))}
          </div>
        </div>
      </div>
      <table className="clients-table">
        <thead>
          <tr>
            <th>Nom et Prénom</th>
            <th>Adresse Mail</th>
            <th>Téléphone</th>
            <th>Statut</th>
            <th>Nb. Interventions</th>
            <th>Nb. Clients</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id} onClick={() => handleClientClick(client)}>
              <td><img src={client.avatar} alt={client.name} className="client-avatar" />{client.firstName} {client.lastName}</td>
              <td>{client.email}</td>
              <td>{client.phone}</td>
              <td><span className={`status ${client.status === 'Payé' ? 'paid' : 'unpaid'}`}>{client.status}</span></td>
              <td>{client.interventions}</td>
              <td>{client.clients}</td>
              <td>
                <button className="action-button mail-button">Mail</button>
                <button className="action-button delete-button" onClick={() => handleDeleteClient(client.id)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedClient && (
        <div className="detail-popup">
          <div className="detail-popup-content">
            <h3>Détails du Client</h3>
            <img src={selectedClient.avatar} alt={selectedClient.name} className="client-avatar" />
            <p><strong>Nom et Prénom:</strong> {selectedClient.firstName} {selectedClient.lastName}</p>
            <p><strong>Email:</strong> {selectedClient.email}</p>
            <p><strong>Téléphone:</strong> {selectedClient.phone}</p>
            <p><strong>Statut:</strong> {selectedClient.status}</p>
            <p><strong>Nb. Interventions:</strong> {selectedClient.interventions}</p>
            <p><strong>Nb. Clients:</strong> {selectedClient.clients}</p>
            <h4>Tâches:</h4>
            <ul>
              {selectedClient.tasks?.map(task => (
                <li key={task.id}>
                  <p><strong>Titre:</strong> {task.title}</p>
                </li>
              ))}
            </ul>
            <button onClick={() => setSelectedClient(null)} className="close-popup-button">Fermer</button>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Clients;
