import React from "react";
import "./Modal.css";

// Définition des types pour les props
interface ModalProps {
  onClose?: () => void; // La fonction onClose est optionnelle
  children?: React.ReactNode; // Les enfants peuvent être n'importe quel élément React
}

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <div
      className="modal"
      onClick={() => (props.onClose ? props.onClose() : undefined)}
    >
      <div
        className="modal_content custom-scroll"
        onClick={(event) => event.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
