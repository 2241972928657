import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Importer le contexte
import './Inscription.css';

const Inscription: React.FC = () => {
    const [nom, setNom] = useState('');
    const [prenoms, setPrenoms] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUpperCase: false,
        hasLowerCase: false,
    });
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const navigate = useNavigate();
    const { setIsAuthenticated, setUserEmail } = useAuth(); // Utiliser le contexte

    const validatePassword = (password: string) => {
        const criteria = {
            minLength: /.{8,}/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*]/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
        };
        setPasswordCriteria(criteria);
        return criteria;
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        validatePassword(value);
        setPasswordsMatch(value === confirmPassword);
    }

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setConfirmPassword(value);
        setPasswordsMatch(value === password);
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors: string[] = [];

        if (!email.endsWith('@wecyde.com')) {
            newErrors.push('Veuillez utiliser une adresse e-mail se terminant par @wecyde.com.');
        }

        const passwordErrors = Object.keys(passwordCriteria).filter(key => !passwordCriteria[key as keyof typeof passwordCriteria]);
        if (passwordErrors.length > 0) {
            newErrors.push('Le mot de passe doit respecter tous les critères.');
        }

        if (password !== confirmPassword) {
            newErrors.push('Les mots de passe ne correspondent pas.');
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        // Envoi de la requête d'inscription à l'API
        try {
            const response = await fetch('https://crm.alwaysdata.net/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    confirmPassword,
                    firstName: prenoms,
                    lastName: nom,
                }),
            });

            if (response.ok) {
                // Marquer l'utilisateur comme authentifié
                setIsAuthenticated(true);
                setUserEmail(email); // Stocker l'email
                // Rediriger vers la page de sélection de projet
                navigate('/project-selection');
            } else {
                const errorText = await response.text();
                setErrors([errorText]);
            }
        } catch (error) {
            setErrors(['Une erreur s\'est produite. Veuillez réessayer.']);
        }
    }

    const getCriteriaClassName = (isValid: boolean, isDirty: boolean) => {
        return isValid ? 'valid' : isDirty ? 'invalid' : '';
    }

    return (
        <div className="inscription-container">
            <h2>Inscription</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Nom:</label>
                    <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Prénoms:</label>
                    <input type="text" value={prenoms} onChange={(e) => setPrenoms(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Mot de passe:</label>
                    <input type="password" value={password} onChange={handlePasswordChange} required />
                    <ul className="password-info">
                        <li className={getCriteriaClassName(passwordCriteria.minLength, password.length > 0)}>
                            <span className="criteria-point"></span>Le mot de passe doit contenir au moins 8 caractères.
                        </li>
                        <li className={getCriteriaClassName(passwordCriteria.hasNumber, password.length > 0)}>
                            <span className="criteria-point"></span>Le mot de passe doit contenir au moins un chiffre.
                        </li>
                        <li className={getCriteriaClassName(passwordCriteria.hasSpecialChar, password.length > 0)}>
                            <span className="criteria-point"></span>Le mot de passe doit contenir au moins un caractère spécial.
                        </li>
                        <li className={getCriteriaClassName(passwordCriteria.hasUpperCase, password.length > 0)}>
                            <span className="criteria-point"></span>Le mot de passe doit contenir au moins une lettre majuscule.
                        </li>
                        <li className={getCriteriaClassName(passwordCriteria.hasLowerCase, password.length > 0)}>
                            <span className="criteria-point"></span>Le mot de passe doit contenir au moins une lettre minuscule.
                        </li>
                    </ul>
                </div>
                <div className="form-group">
                    <label>Confirmer le mot de passe:</label>
                    <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />
                    {confirmPassword.length > 0 && (
                        <p className={passwordsMatch ? 'valid' : 'invalid'}>
                            {passwordsMatch ? 'Les mots de passe correspondent.' : 'Les mots de passe ne correspondent pas.'}
                        </p>
                    )}
                </div>
                {errors.length > 0 && (
                    <div className="error">
                        {errors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                )}
                <button type="submit">S'inscrire</button>
            </form>
        </div>
    );
}

export default Inscription;
