import React, { useEffect, useState } from "react";
import { Calendar, CheckSquare, List, Tag, Trash, Type, X } from "react-feather";

import Modal from "../../Modal/Modal";
import Editable from "../../Editabled/Editable";
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import "./CardInfo.css";

// Définition des types pour les labels et les tâches
interface Label {
  color: string;
  text: string;
}

interface Task {
  id: string;
  text: string;
  completed: boolean;
}

// Définition du type pour une carte
interface CardType {
  id: string;
  title: string;
  desc?: string;
  date?: string;
  tasks?: Task[];
  labels?: Label[];
}

interface CardInfoProps {
  card: CardType;
  boardId: string;
  updateCard: (bid: string, cid: string, card: CardType) => void;
  onClose: () => void;
}

const CardInfo: React.FC<CardInfoProps> = (props) => {
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState<string | undefined>(undefined);
  const [values, setValues] = useState<CardType>({ ...props.card });
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('authToken'); 

  const colors = [
    "#a8193d",
    "#4fcc25",
    "#1ebffa",
    "#8da377",
    "#9975bd",
    "#cf61a1",
    "#240959",
  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log('Fetching user details for:', userEmail);
        const response = await fetch(`https://crm.alwaysdata.net/getUserByEmail?email=${encodeURIComponent(userEmail)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUserInfo(data);
        setUserToken(data.token);
        console.log('User details fetched:', data);
        verifyToken(data.token);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Erreur lors de la récupération des détails de l\'utilisateur');
      }
    };

    const verifyToken = async (token: string) => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/verifyToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to verify token');
        }

        const data = await response.json();
        if (data.valid) {
          console.log('Token is valid');
          setIsTokenValid(true);
          decodeToken(token); 
        } else {
          console.log('Token is invalid');
          setIsTokenValid(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsTokenValid(false);
        navigate('/login');
      }
    };

    const decodeToken = async (token: string) => {
      try {
        console.log('Decoding token:', token);
        const response = await fetch('https://crm.alwaysdata.net/decodeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error('Failed to decode token');
        }

        const data = await response.json();
        console.log('Decoded token:', data);
        setProjectId(data.projectId);
        console.log('Project ID:', data.projectId); 
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, navigate]);

  const updateTitle = (value: string) => {
    setValues((prevValues) => ({ ...prevValues, title: value }));
  };

  const updateDesc = (value: string) => {
    setValues((prevValues) => ({ ...prevValues, desc: value }));
  };

  const addLabel = (label: Label) => {
    const index = values.labels?.findIndex((item) => item.text === label.text);
    if (index !== undefined && index > -1) return;

    setSelectedColor(undefined);
    setValues((prevValues) => ({
      ...prevValues,
      labels: [...(prevValues.labels || []), label],
    }));
  };

  const removeLabel = (label: Label) => {
    setValues((prevValues) => ({
      ...prevValues,
      labels: (prevValues.labels || []).filter((item) => item.text !== label.text),
    }));
  };

  const addTask = (value: string) => {
    const task = {
      id: Date.now() + Math.random() * 2 + "",
      completed: false,
      text: value,
    };
    setValues((prevValues) => ({
      ...prevValues,
      tasks: [...(prevValues.tasks || []), task],
    }));
  };

  const removeTask = (id: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      tasks: (prevValues.tasks || []).filter((item) => item.id !== id),
    }));
  };

  const updateTask = (id: string, completed: boolean) => {
    setValues((prevValues) => {
      const tasks = (prevValues.tasks || []).map((task) =>
        task.id === id ? { ...task, completed } : task
      );
      return { ...prevValues, tasks };
    });
  };

  const calculatePercent = (): number => {
    const totalTasks = values.tasks?.length || 0;
    const completedTasks = values.tasks?.filter((item) => item.completed).length || 0;
    return totalTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;
  };

  const updateDate = (date: string) => {
    if (!date || isNaN(Date.parse(date))) {
      console.error('Invalid date value:', date);
      return;
    }
    setValues((prevValues) => ({ ...prevValues, date }));
  };

  useEffect(() => {
    if (values !== props.card) {
      props.updateCard(props.boardId, values.id, values);
    }
  }, [values, props]);

  return (
    <Modal onClose={props.onClose}>
      <div className="cardinfo">
        <div className="cardinfo_box">
          <div className="cardinfo_box_title">
            <Type />
            <p>Titre</p>
          </div>
          <Editable
            defaultValue={values.title}
            text={values.title}
            placeholder="Enter Title"
            onSubmit={updateTitle}
          />
        </div>

        <div className="cardinfo_box">
          <div className="cardinfo_box_title">
            <List />
            <p>Description</p>
          </div>
          <Editable
            defaultValue={values.desc}
            text={values.desc || "Ajoutez une description"}
            placeholder="Valider"
            onSubmit={updateDesc}
          />
        </div>

        <div className="cardinfo_box">
          <div className="cardinfo_box_title">
            <Calendar />
            <p>Date</p>
          </div>
          <input
            type="date"
            defaultValue={values.date}
            min={new Date().toISOString().substr(0, 10)}
            onChange={(event) => updateDate(event.target.value)}
          />
        </div>

        <div className="cardinfo_box">
          <div className="cardinfo_box_title">
            <Tag />
            <p>Labels</p>
          </div>
          <div className="cardinfo_box_labels">
            {values.labels?.map((item, index) => (
              <label
                key={index}
                style={{ backgroundColor: item.color, color: "#fff" }}
              >
                {item.text}
                <X onClick={() => removeLabel(item)} />
              </label>
            ))}
          </div>
          <ul>
            {colors.map((item: string, index: number) => (
              <li
                key={index + item}
                style={{ backgroundColor: item }}
                className={selectedColor === item ? "li_active" : ""}
                onClick={() => setSelectedColor(item)}
              />
            ))}
          </ul>
          <Editable
            text="Ajouter un label"
            placeholder="Entrez du texte pour le label"
            onSubmit={(value) =>
              addLabel({ color: selectedColor || "", text: value })
            }
          />
        </div>

        <div className="cardinfo_box">
          <div className="cardinfo_box_title">
            <CheckSquare />
            <p>Tâches</p>
          </div>
          <div className="cardinfo_box_progress-bar">
            <div
              className="cardinfo_box_progress"
              style={{
                width: `${calculatePercent()}%`,
                backgroundColor: calculatePercent() === 100 ? "lightblue" : "",
              }}
            />
          </div>
          <div className="cardinfo_box_task_list">
            {values.tasks?.map((item) => (
              <div key={item.id} className="cardinfo_box_task_checkbox">
                <input
                  type="checkbox"
                  checked={item.completed}
                  onChange={(event) =>
                    updateTask(item.id, event.target.checked)
                  }
                />
                <p className={item.completed ? "completed" : ""}>{item.text}</p>
                <Trash onClick={() => removeTask(item.id)} />
              </div>
            ))}
          </div>
          <Editable
            text="Ajouter une tâche"
            placeholder="Entrez une tâche"
            onSubmit={addTask}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CardInfo;
