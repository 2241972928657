import React, { useState } from "react";
import { MoreHorizontal } from "react-feather";
import Card from "../Card/Card";
import Dropdown from "../Dropdown/Dropdown";
import Editable from "../Editabled/Editable";
import { BoardType, CardType } from "../types";
import "./Board.css";

interface BoardProps {
  board: BoardType;
  removeBoard: () => void;
  removeCard: (bid: string, cid: string) => void;
  dragEntered: (bid: string, cid: string) => void;
  dragEnded: (bid: string, cid: string) => void;
  updateCard: (bid: string, cid: string, card: CardType) => void;
  addCard: (bid: string, text: string) => void;
}

const Board: React.FC<BoardProps> = (props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div className="board">
      <div className="board_header">
        <p className="board_header_title">
          {props.board?.title}
          <span>({props.board?.cards?.length || 0})</span>
        </p>
        <div
          className="board_header_title_more"
          onClick={() => setShowDropdown(true)}
        >
          <MoreHorizontal />
          {showDropdown && (
            <Dropdown
              className="board_dropdown" // Utilisez className au lieu de class
              onClose={() => setShowDropdown(false)}
            >
              <p onClick={() => props.removeBoard()}>Supprimer la liste</p>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="board_cards custom-scroll">
        {props.board?.cards?.map((item) => (
          <Card
            key={item.id}
            card={item}
            boardId={props.board.id}
            removeCard={props.removeCard}
            dragEntered={props.dragEntered}
            dragEnded={props.dragEnded}
            updateCard={props.updateCard}
          />
        ))}
        <Editable
          text="Ajouter une carte"
          placeholder="Entrez le nom de la carte"
          displayClass="board_add-card"
          editClass="board_add-card_edit"
          onSubmit={(value) => props.addCard(props.board?.id, value)}
        />
      </div>
    </div>
  );
};

export default Board;
