import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Importer le contexte
import './ProjectSelection.css';

interface ProjectSelectionProps {
  setSelectedProject: (projectId: string) => void;
}

interface Project {
  projectId: string;
  projectName: string;
  description: string;
}

const ProjectSelection: React.FC<ProjectSelectionProps> = ({ setSelectedProject }) => {
  const [selectedProject, setSelectedProjectState] = useState<string | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { userEmail } = useAuth(); // Utiliser le contexte pour récupérer l'email

  useEffect(() => {
    console.log('User email:', userEmail); // Afficher l'email de l'utilisateur dans la console

    const fetchProjects = async () => {
      try {
        const response = await fetch('https://crm.alwaysdata.net/projects');
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        console.log('Projects data:', data); // Log the API response
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error); // Log any error
        setError('Erreur lors de la récupération des projets');
      }
    };

    fetchProjects();
  }, [userEmail]);

  const handleProjectSelect = (projectId: string) => {
    console.log('Selected project ID:', projectId);
    console.log('User email:', userEmail);
    setSelectedProjectState(projectId);
  };

  const handleContinue = async () => {
    if (selectedProject && userEmail) {
      try {
        const response = await fetch('https://crm.alwaysdata.net/generateToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userEmail, projectId: selectedProject }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Token généré :', data.token);
          localStorage.setItem('token', data.token); // Stocker le token dans le localStorage
          setSelectedProject(selectedProject); // Mettez à jour l'état du projet sélectionné
          navigate(`/dashboard/${selectedProject}`); // Rediriger vers la page du tableau de bord du projet
        } else {
          const errorText = await response.text();
          console.error('Error generating token:', errorText);
          setError('Erreur lors de la génération du token');
        }
      } catch (error) {
        console.error('Catch error:', error);
        setError('Une erreur s\'est produite. Veuillez réessayer.');
      }
    }
  };

  return (
    <div className="project-selection-container">
      <h2>Sélectionnez un projet</h2>
      {error && <p className="error">{error}</p>}
      <p>Connecté en tant que : {userEmail}</p> {/* Afficher l'email de l'utilisateur */}
      <div className="project-card-list">
        {projects.map(project => (
          <div
            key={project.projectId}
            className={`project-card ${selectedProject === project.projectId ? 'selected' : ''}`}
            onClick={() => handleProjectSelect(project.projectId)}
          >
            <h3>{project.projectName}</h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
      <button
        onClick={handleContinue}
        disabled={!selectedProject}
        className="continue-button"
      >
        Continuer
      </button>
    </div>
  );
};

export default ProjectSelection;
